<div class="success-container" [ngClass]="{'bundle': ['cv','campaign','pay'].includes(this.data['data'])}"
    *ngIf="data['data'] !== 'save CV' && data['data'] !== 'invite to apply' && data['data'] !== 'Insufficient Credits' && data['data'] !== 'Insufficient Credits CV' && data['data'] !== 'Campaign Payment Successful' && data['data'] !== 'Campaign Successful' && data['data'] !== 'CV Unlocked Successful' && data['data'] !== 'create new folder' && data['data'] !== 'help-successful' && data['data'] !== 'sandbox CV'">
    <div class="close-icon" (click)="onNoClick()">
        <span class="close"><a>&#10006;</a></span>
    </div>

    <div class="img-div">
        <img src="../../../../assets/images/tick-mark.png" class="tick-mark"
            *ngIf="this.data !== 'not 3mtt user' && this.data !== 'not WorkNigeria user' && this.data !== 'less CVs'" />
        <img src="../../../../assets/images/bell-icon-colored.png" class="tick-mark"
            *ngIf="this.data === 'not 3mtt user' || this.data === 'not WorkNigeria user' || this.data === 'less CVs'" />
    </div>

    <div *ngIf="this.data === 'not 3mtt user'" class="daily-alert">
        <p>
            You are not a 3MTT User, please login through WorkNigeria.
        </p>
        <button (click)="goToHomePage()">
            OK
        </button>
    </div>

    <div *ngIf="this.data['data'] === 'brochure success'" class="download-success">
        <p>
            Your download is on it's way
        </p>
    </div>

    <div *ngIf="this.data['data'] === 'background check confirms'" class="daily-alert">
        <p>Your request has been submitted. A member of our team will conatct to you</p>
        <button class="btn-background-ok" (click)="goToServices()">
            OK
        </button>
    </div>

    <div *ngIf="this.data['data'] === 'email verification success'" class="daily-alert">
        <p>Your Company Email has been Verified You can post job</p>
        <button (click)="goToDashboard()">
            Proceed to Dashboard
        </button>
    </div>

    <div *ngIf="this.data === 'not WorkNigeria user'" class="daily-alert">
        <p>
            You are a 3MTT User, please login through 3MTT website.
        </p>
        <button (click)="goTo3MTT()">
            OK
        </button>
    </div>
    <div *ngIf="this.data['data'] === 'daily job alert'" class="daily-alert">
        <p>
            You have successfully subscribe for <b>WorkNigeria</b> daily job alert
        </p>
        <button (click)="onNoClick()">
            OK
        </button>
    </div>
    <div *ngIf="this.data['data'] === 'news blogs'" class="daily-alert">
        <p>
            Subscription added successfully!
        </p>
        <button (click)="onNoClick()">
            OK
        </button>
    </div>
    <div *ngIf="this.data['data'] === 'unsubscribed daily alerts'" class="daily-alert">
        <p>
            Daily Alerts <b>unsubscribed</b> successfully!
        </p>
        <button (click)="onNoClick()">
            OK
        </button>
    </div>
    <div *ngIf="this.data['data'] === 'from dashboard add job'" class="daily-alert">
        <p>
            Your job has been posted successfully
        </p>
        <button style="color: #FC6748" (click)="postJob()">
            Post Another Job
        </button>
        <button class="mt-1" (click)="goToDashboard()">
            Go to Dashboard
        </button>
    </div>
    <div *ngIf="this.data['data'] === 'from homepage add job'" class="daily-alert">
        <p>
            Your job has been posted successfully
        </p>
        <button style="color: #FC6748" (click)="postJob()">
            Post Another Job
        </button>
        <button class="mt-1" (click)="goToJobs()">
            Go to Homepage
        </button>
    </div>
    <!-- In app survery rating -->
    <div class="rating-popup" *ngIf="this.data['data'] === 'first time job post'">
        <h2>Rate Your Experience</h2>
        <form [formGroup]="userFeedbackForm">
            <div class="stars">
                <mat-icon *ngFor="let star of stars; let i = index" (click)="setRating(i + 1)" 
                    [ngClass]="{'active': i < rating}">
                    star
                </mat-icon>
            </div>
            <textarea class="comment-box" formControlName="comment"  placeholder="Add a comment (optional)"
                rows="2"></textarea>
            <button mat-raised-button color="primary" [disabled]="userFeedbackForm.invalid" (click)="submitRating()">Submit</button>
        </form>
    </div>
    <div *ngIf="this.data['data'] === 'from dashboard edit job'" class="daily-alert">
        <p>
            Your job has been updated successfully
        </p>
        <button (click)="goToDashboard()">
            OK
        </button>
    </div>
    <div *ngIf="this.data['data'] === 'from header edit job'" class="daily-alert">
        <p>
            Your job has been updated successfully
        </p>
        <button (click)="goToJobs()">
            OK
        </button>
    </div>
    <div *ngIf="this.data['data'] === 'verify company'" class="daily-alert">
        <p>
            Your Company has been Verified
        </p>
        <button (click)="goToDashboard()" class="mt-5">
            Return Home
        </button>
    </div>
    <div *ngIf="this.data['data'] === 'saved CVs' " class="daily-alert">
        <p>
            CVs Uploaded Successfully
        </p>
        <button (click)="onNoClick()">
            OK
        </button>
    </div>

    <div *ngIf="this.data['data'] === 'less CVs' " class="daily-alert">
        <p>
            You can not upload more than <b>5</b> CVs
        </p>
        <button (click)="onNoClick()">
            OK
        </button>
    </div>
    <div *ngIf="this.data['data'] === 'Silver' || this.data['data'] === 'Gold' || this.data['data'] === 'Bronze'"
        class="daily-alert plan">
        <p>
            Your subscription for <b>{{ this.data['data'] }} Plan</b> was successful
        </p>
        <div style="display: flex; gap: 10px">
            <button style="color: #FC6748" (click)="onNoClick()">
                OK
            </button>
        </div>
    </div>
    <div *ngIf="this.data['data'] === 'campaign'" class="daily-alert">
        <p>
            You have successfully purchased a <b>(Pack of 5)</b>. 5 Promotion Credit has been added to your plan
        </p>
        <button (click)="goToDashboard()">
            Return to Dashboard
        </button>
    </div>
    <div *ngIf="this.data['data'] === 'cv'" class="daily-alert">
        <p>
            You have successfully purchased a <b>(Pack 25 CV)</b> 25 CV Credit has been added to your plan
        </p>
        <button (click)="goToDashboard()">
            Return to Dashboard
        </button>
    </div>
    <div *ngIf="this.data['data'] === 'pay'" class="daily-alert">
        <p>
            Wallet funded successfully
        </p>
        <button (click)="goToDashboard()">
            Return to Dashboard
        </button>
    </div>
</div>

<!-- These pop ups are for saving candidate CV -->
<div class="success-container-2" *ngIf="data['data'] === 'save CV' && !saveCandidate && !saveInFolder">
    <div class="close-icon" (click)="onNoClick()">
        <span class="close"><a>&#10006;</a></span>
    </div>
    <p class="select-text">Select a Folder to save</p>
    <mat-form-field appearance="fill" class="custom-mat-dropdown">
        <mat-label>Select Folder</mat-label>
        <mat-select (selectionChange)="onFolderSelect($event)">
            <mat-option *ngFor="let folder of folders" [value]="folder.id">
                {{ folder.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button class="btn-new-folder" (click)="newFolder()"><img class="green-plus-icon"
            src="../../../../assets/images/green-plus-icon.png" />Create New Folder</button>
    <button class="btn-done" (click)="saveCV(data['jobDetails'].id, selectedFolderId, selectedFolderName, 'old')">
        Done
    </button>
</div>

<div class="success-container-2" *ngIf="saveInFolder && !saveCandidate">
    <div class="close-icon" (click)="onNoClick()">
        <span class="close"><a>&#10006;</a></span>
    </div>
    <div>
        <p class="folder-text">Create New Folder</p>
        <mat-form-field appearance="fill" class="custom-mat-input">
            <mat-label>Enter New Folder Name</mat-label>
            <input matInput [(ngModel)]="selectedFolderName">
        </mat-form-field>
        <button class="btn-done" (click)="saveCV(data['jobDetails'].id, selectedFolderId, selectedFolderName, 'new')">
            Done
        </button>
    </div>
</div>

<div class="success-container-2" *ngIf="data['data'] === 'create new folder'">
    <div class="close-icon" (click)="onNoClick()">
        <span class="close"><a>&#10006;</a></span>
    </div>
    <div>
        <p class="folder-text">Create New Folder</p>
        <mat-form-field appearance="fill" class="custom-mat-input">
            <mat-label>Enter New Folder Name</mat-label>
            <input matInput [(ngModel)]="selectedFolderName">
        </mat-form-field>
        <button class="btn-done" (click)="createEmptyFolder(selectedFolderName)">
            Done
        </button>
    </div>
</div>

<div class="success-container-2" *ngIf="saveCandidate && !saveInFolder">
    <div class="close-icon" (click)="onNoClick()">
        <span class="close"><a>&#10006;</a></span>
    </div>
    <div class="img-div">
        <img src="../../../../assets/images/tick-mark.png" class="tick-mark" />
        <p>Candidate Saved Successfully!</p>
    </div>
</div>

<div class="success-container-3" *ngIf="data['data'] === 'invite to apply' && !inviteToApply">
    <div class="close-icon" (click)="onNoClick()">
        <span class="close"><a>&#10006;</a></span>
    </div>
    <div class="invite-heading">
        <p class="invite-text">
            Invite
        </p>
        <p class="seeker-name-text">
            {{data['jobDetails'].firstName}} {{ data['jobDetails'].lastName}}
        </p>
    </div>
    <div class="job-list-table">
        <p>
            to apply for select job
        </p>
    </div>
    <div class="well-views table-responsive">
        <table class="table jobs">
            <tbody>
                <tr *ngFor="let job of jobLists">
                    <td>
                        <mat-checkbox (change)="updateSelectedJobsList($event, job.id)"
                            [checked]="jobLists.indexOf(job.id) !== -1"></mat-checkbox>
                    </td>
                    <td>{{ job.title }}</td>
                    <td>{{ locationType(job.location_type) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <button class="btn-send-invite" (click)="sendInvite()">
        Send Invite
    </button>
</div>

<div class="success-container" *ngIf="inviteToApply">
    <div class="close-icon" (click)="onNoClick()">
        <span class="close"><a>&#10006;</a></span>
    </div>
    <div class="img-div">
        <img src="../../../../assets/images/tick-mark.png" class="tick-mark" />
    </div>
    <div class="daily-alert">
        <p>
            Your Invitation was Sent <br> <b>Successfully</b>
        </p>
        <button (click)="onNoClick()">
            Return To Saved CV
        </button>
    </div>
</div>

<div class="insufficient-credits-container" *ngIf="data['data'] === 'Insufficient Credits'">
    <div class="close-icon" (click)="onNoClick()">
        <span class="close"><a>&#10006;</a></span>
    </div>

    <div class="img-div">
        <img src="../../../../assets/images/insufficient-credits.webp" />
    </div>
    <div class="daily-alert insufficient-credits">
        <p>
            <strong>Oh!</strong> your credit balance can only<br>promote <strong>{{ this.data.allowedPromotions || 0 }}
                Jobs</strong>. How would you like to<br>promote the rest
        </p>
        <div style="display: flex; flex-direction: column; gap: 10px">
            <button [disabled]="this.data?.subscription === 'Gold'" class="upgrade-plan"
                [ngClass]="{'disabled': this.data?.subscription === 'Gold'}" (click)="upgradePlan()">
                UPGRADE PLAN
            </button>
            <button class="purchase-pack" (click)="purchaseBundle('campaign')">
                PURCHASE A PACK
            </button>
            <button class="pay-from-wallet" (click)="payFromWallet()">
                PAY FROM WALLET
            </button>
        </div>
    </div>
</div>

<div class="insufficient-credits-container" *ngIf="data['data'] === 'Insufficient Credits CV'">
    <div class="close-icon" (click)="onNoClick()">
        <span class="close"><a>&#10006;</a></span>
    </div>

    <div class="img-div">
        <img src="../../../../assets/images/insufficient-credits-cv.webp" />
    </div>
    <div class="daily-alert insufficient-credits">
        <p>
            Sorry! your wallet balance is not <br> sufficient to <strong>View Candidate Contact</strong>
        </p>
        <p style="margin: -15px 0 20px 0;">You are on {{this.data?.subscription}} Plan</p>
        <div style="display: flex; flex-direction: column; gap: 10px">
            <button [disabled]="this.data?.subscription === 'Gold'" class="upgrade-plan"
                [ngClass]="{'disabled': this.data?.subscription === 'Gold'}" (click)="upgradePlan()">
                UPGRADE PLAN
            </button>
            <button class="purchase-pack" (click)="purchaseBundle('cv')">
                PURCHASE A PACK
            </button>
            <button class="pay-from-wallet" (click)="payFromWallet()">
                PAY FROM WALLET
            </button>
        </div>
    </div>
</div>

<div class="success-container-payment" *ngIf="this.data['data'] === 'Campaign Payment Successful'">
    <div class="close-icon" (click)="onNoClick()">
        <span class="close"><a>&#10006;</a></span>
    </div>

    <div class="img-div">
        <img src="../../../../assets/images/tick-mark.png" class="tick-mark" />
    </div>

    <div class="daily-alert payment-success">
        <h2>
            Payment Successful
        </h2>
        <p *ngIf="this.data['from'] === 'wallet' || this.data['from'] === 'wallet-cv'">
            Your <strong>Wallet</strong> has been <strong>Funded</strong>
        </p>
        <p *ngIf="this.data['from'] === 'bundle'">
            You have successfully purchased <strong>A pack of Five Campaign Bundle</strong>
        </p>
        <p *ngIf="this.data['from'] === 'cv-bundle'">
            You have successfully purchased <strong>A pack of 25 CV</strong>
        </p>
        <p *ngIf="this.data['from'] === 'subscription' || this.data['from'] === 'subscription-cv'">
            You have upgraded to <strong>{{this.data['subscription'] ? this.data['subscription'] : this.data['plan']}}
                Plan</strong>
        </p>
        <div style="display: flex; gap: 13px; margin-top: 72px;">
            <button class="cancel" (click)="onNoClick()">
                CANCEL
            </button>
            <button
                *ngIf="this.data['from'] !== 'cv-bundle' && this.data['from'] !== 'subscription-cv' && this.data['from'] !== 'wallet-cv'"
                (click)="postCampaign()">
                PROMOTE JOBS
            </button>
            <button
                *ngIf="this.data['from'] === 'cv-bundle' || this.data['from'] === 'subscription-cv' || this.data['from'] === 'wallet-cv'"
                (click)="unlockCV()">
                RETURN TO CV
            </button>
        </div>
    </div>
</div>

<div class="success-container-payment" *ngIf="this.data['data'] === 'Campaign Successful'">
    <div class="close-icon" (click)="goToCampaignDashboard()">
        <span class="close"><a>&#10006;</a></span>
    </div>

    <div class="img-div">
        <img src="../../../../assets/images/clap.webp" class="tick-mark" />
    </div>

    <div class="daily-alert payment-success">
        <h2>
            Congratulations
        </h2>
        <p>
            You have promoted {{ this.data.campaignDetails.jobIds.length }}
            {{ this.data.campaignDetails.jobIds.length === 1 ? 'job' : 'jobs' }}
        </p>
        <div style="display: flex; gap: 13px; margin-top: 90px;">
            <button (click)="goToCampaignDashboard()">
                RETURN TO CAMPAIGN DASHBOARD
            </button>
        </div>
    </div>
</div>

<div class="success-container-payment" *ngIf="this.data['data'] === 'CV Unlocked Successful'">
    <div class="close-icon" (click)="goToCampaignDashboard()">
        <span class="close"><a>&#10006;</a></span>
    </div>

    <div class="img-div">
        <img src="../../../../assets/images/clap.webp" class="tick-mark" />
    </div>

    <div class="daily-alert payment-success">
        <h2>
            Congratulations
        </h2>
        <p>
            Your CV has unlocked!
        </p>
        <div style="display: flex; gap: 13px; margin-top: 90px;">
            <button (click)="goToDashboard()">
                RETURN TO CV
            </button>
        </div>
    </div>
</div>

<div class="success-container-help" *ngIf="this.data['data'] === 'help-successful'">
    <div class="close-icon" (click)="goToDashboard()">
        <span class="close"><a>&#10006;</a></span>
    </div>
    <div class="daily-alert payment-success">
        <h2 style="font-size: 24px !important;">
            Thank you for contacting Us. Our support team will reach out to you soon
        </h2>
        <div style="display: flex; gap: 13px; margin-top: 90px;">
            <button (click)="goToDashboard()">
                Return to Dashboard
            </button>
        </div>
    </div>
</div>

<!-- This is for sandbox CV upload success -->
<div *ngIf="this.data['data'] === 'sandbox CV'" class="success-sandbox-container">
    <div class="close-icon" (click)="onNoClick()">
        <span class="close"><a>&#10006;</a></span>
    </div>
    <div class="img-div">
        <img src="../../../../assets/images/sandbox-save-cv.png" class="tick-mark" />
    </div>
    <h3>Thank You {{ this.data['firstName'] }}</h3>
    <p>
        We have received your Resume
    </p>
    <p class="p-bold">
        Sign up to receive daily email notifications for jobs that matches your peferences
    </p>
    <div class="btns-div">
        <button (click)="goToSignUp()" class="btn-new-account">
            Sign Up
        </button>
        <br>
        <button class="btn-later" (click)="onNoClick()">Maybe Later</button>
    </div>
</div>